import React, { createContext } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';

// Create and configure your Cloudinary instance.
const cld = new Cloudinary({
  cloud: {
    cloudName: 'dgplqcwf3',
  },
});

export const CloudinaryContext = createContext(cld);

export const CloudinaryProvider = ({ children }) => {
  return (
    <CloudinaryContext.Provider value={cld}>
      {children}
    </CloudinaryContext.Provider>
  );
};

export default CloudinaryContext;
