import React, { Fragment } from 'react';

import { CloudinaryProvider } from '../context/CloudinaryProvider';

const RootElement = ({ children }) => {
  return (
    <Fragment>
      <CloudinaryProvider>{children}</CloudinaryProvider>
    </Fragment>
  );
};

export default RootElement;
